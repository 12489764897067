export default class {
    constructor({
        id,
        formId,
        formEventLabel,
        activeClass,
        selectArrowMarkup,
        submitBtnMarkup,
        formNotes,
        proxyFormUrl,
        postData,
        registerUser,
        passwordFieldMarkup,
        registerFormId,
        translations,
        successUrl,
        siteLanguage,
    }) {
        const baseUrl = '//go.rockwool.com';
        const munchkinId = '197-QUE-642';
        const fieldWrapClass = 'mktoFieldWrap';
        const asteriskFieldClass = 'mktoAsterix';
        const inputClass = 'mktoField';
        const submitWrapClass = 'mktoButtonWrap';

        // Elements and class variables
        const el = document.getElementById(id);

        // Event handler functions
        function showLabel(wrapper) {
            if (wrapper) wrapper.classList.add(activeClass);
        }
        function hideLabel(wrapper) {
            if (wrapper) wrapper.classList.remove(activeClass);
        }
        function handleCommonLabels() {
            // nodes are dynamic DOM additions and can only be captured after the form loads
            const inputs = [...el.querySelectorAll(`.${inputClass}`)]
                .filter(i => i.tagName !== 'SELECT' && i.type !== 'checkbox' && i.type !== 'hidden');

            Array.from(inputs).forEach(o => {
                const labelCont = o.closest(`.${fieldWrapClass}`);

                o.addEventListener('focus', () => {
                    showLabel(labelCont);
                });
                o.addEventListener('blur', () => {
                    if (o.value === '') {
                        hideLabel(labelCont);
                    }
                });
            });
        }
        function handleSelectLabels() {
            // nodes are dynamic DOM additions and can only be captured after the form loads
            const selects = el.querySelectorAll('select');

            // select labels are always visible
            Array.from(selects).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));

                const selectArrow = document.createElement('div');
                selectArrow.classList.add('arrow');
                selectArrow.innerHTML = selectArrowMarkup;
                o.closest(`.${fieldWrapClass}`).append(selectArrow);
            });
        }
        function handleCheckboxes() {
            const checkboxes = el.querySelectorAll('input[type="checkbox"]');
            Array.from(checkboxes).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));
            });

            const checkboxGroups = el.querySelectorAll('.mktoCheckboxList');
            Array.from(checkboxGroups).forEach(o => {
                if (o.querySelectorAll('input[type="checkbox"]').length === 1) {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-checkbox');
                } else {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-checkboxes');
                }
            });
        }
        function handleDateLabels() {
            // nodes are dynamic DOM additions and can only be captured after the form loads
            const dates = el.querySelectorAll('input[type="date"]');

            // date labels are always visible
            Array.from(dates).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));
                o.closest(`.${fieldWrapClass}`).classList.add('is-date');
            });
        }
        function handleRadios() {
            const radios = el.querySelectorAll('input[type="radio"]');
            Array.from(radios).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));
            });

            const radioGroups = el.querySelectorAll('.mktoRadioList');
            Array.from(radioGroups).forEach(o => {
                if (o.querySelectorAll('input[type="radio"]').length === 1) {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-radio');
                } else {
                    o.closest(`.${fieldWrapClass}`).classList.add('is-radios');
                }
            });
        }
        function handlePrepopulatedInputs(form) {
            const nonEmpty = Object.fromEntries(
                Object.entries(postData).filter(([_, v]) => v !== ''), /* eslint-disable-line no-unused-vars */
            );

            // populate form input values
            form.vals(nonEmpty);

            // show labels of (non-empty) prepopulated input
            const prepopulatedInputs = [...el.querySelectorAll(`.${inputClass}`)]
                .filter(i => Object.keys(nonEmpty).indexOf(i.getAttribute('name')) !== -1);

            Array.from(prepopulatedInputs).forEach(o => {
                showLabel(o.closest(`.${fieldWrapClass}`));
            });
        }
        function handleNotes() {
            const form = el.querySelector('.mktoForm');
            const submitRow = el.querySelector(`.${submitWrapClass}`).closest('.mktoButtonRow');
            const notesRow = document.createElement('div');
            notesRow.classList.add('formNotesRow');
            notesRow.innerHTML = formNotes;

            form.insertBefore(notesRow, submitRow);
        }
        function handleSubmitBtn() {
            // use custom submit button
            el.querySelector(`.${submitWrapClass}`).innerHTML = submitBtnMarkup;
        }
        function handleAsterisks() {
            // move asterisks in DOM
            const asterisks = el.querySelectorAll(`.${asteriskFieldClass}`);
            Array.from(asterisks).forEach(o => {
                o.closest(`.${fieldWrapClass}`).append(o);
            });
        }
        function translateContent() {
            const placeholderInputs = el.querySelectorAll('input[placeholder]');
            const selects = el.querySelectorAll('select option:first-child');
            const labels = el.querySelectorAll('label');
            const listItems = el.querySelectorAll('ul li');
            const notes = el.querySelectorAll('.note');
            const spans = el.querySelectorAll('.mktoLabel span');

            placeholderInputs.forEach(p => {
                const text = p.placeholder;
                const translation = translations[text];

                if (translation !== '' && translation !== undefined) {
                    p.placeholder = p.placeholder.replace(text, translation);
                }
            });
            selects.forEach(s => {
                const text = s.innerText;
                const translation = translations[text];

                if (translation !== '' && translation !== undefined) {
                    s.innerText = s.innerText.replace(text, translation);
                }
            });
            labels.forEach(l => {
                const text = l.innerText.replace(/[:*]/g, '');
                const translation = translations[text];

                if (translation !== '' && translation !== undefined) {
                    l.innerText = l.innerText.replace(text, translation);
                }
            });
            listItems.forEach(li => {
                const text = li.innerText.replace(/[:*]/g, '');
                const translation = translations[text];

                if (translation !== '' && translation !== undefined) {
                    li.innerText = li.innerText.replace(text, translation);
                }
            });
            notes.forEach(n => {
                const text = [...n.childNodes].find(
                    child => child.nodeType === Node.TEXT_NODE,
                );
                const textTrim = text.textContent.trim();
                const translation = translations[textTrim];

                if (translation !== '' && translation !== undefined) {
                    n.innerHTML = n.innerHTML.replace(textTrim, translation);
                }
            });
            spans.forEach(s => {
                const text = s.innerText.trim();
                const translation = translations[text];

                if (translation !== '' && translation !== undefined) {
                    s.innerText = s.innerText.replace(text, translation);
                }
            });

            // Error text is built dynamically by JS.
            // Need mutation observer to detect creation of error tooltips
            const observer = new MutationObserver(list => {
                list.filter(l => l.type === 'childList')
                    .forEach(l => {
                        l.addedNodes.forEach(n => {
                            if (n.classList && n.classList.contains('mktoError')) {
                                const error = n.querySelector('.mktoErrorMsg');
                                const text = error.innerText.trim();
                                const translation = translations[text.replace(/(\r\n|\n|\r)/gm, ' ')];

                                if (translation !== '' && translation !== undefined) {
                                    error.innerText = error.innerText.replace(text, translation);
                                }
                            }
                        });
                    });
            });

            observer.observe(el, { childList: true, subtree: true });
        }
        function handleAnalyticsEvent() {
            /* eslint-disable no-undef */
            if (typeof dataLayer === 'object' && formEventLabel !== '') {
                dataLayer.push({
                    event: formEventLabel,
                    eventAction: 'submit',
                });
            }
            /* eslint-enable no-undef */
        }
        function handleProxy(form) {
            const proxyForm = document.createElement('form');
            proxyForm.style.display = 'none';
            proxyForm.method = 'post';
            proxyForm.action = proxyFormUrl;

            // prevent Marketo form from being submitted
            form.submittable(false);

            // hijack Marketo submit button to submit the proxy form instead
            el.querySelector('[type="submit"]').addEventListener('click', () => {
                const oInputs = [...el.querySelectorAll(`.${inputClass}`)].filter(i => i.type !== 'hidden');
                Array.from(oInputs).forEach(o => {
                    proxyForm.append(o.cloneNode());
                });
                el.append(proxyForm);

                handleAnalyticsEvent();
                proxyForm.submit();
            });
        }
        function handlePasswordField(form) {
            const $form = form.getFormElem();
            $form.removeAttr('novalidate');

            const fieldsets = el.querySelectorAll('fieldset');
            const pwFieldset = fieldsets[fieldsets.length - 1];
            const rows = pwFieldset.querySelectorAll('.mktoFormRow');
            const lastRow = rows[rows.length - 1];

            const field = document.createElement('div');
            field.innerHTML = passwordFieldMarkup;

            pwFieldset.insertBefore(field, lastRow);
        }
        function handleRegisterUser(data) {
            const registerForm = document.getElementById(registerFormId);
            const passwordInput = registerForm.querySelector('[name="password"]');
            const firstNameInput = registerForm.querySelector('[name="firstName"]');
            const lastNameInput = registerForm.querySelector('[name="lastName"]');
            const emailInput = registerForm.querySelector('[name="email"]');
            const phoneNumberInput = registerForm.querySelector('[name="fields[userPhoneNumber]"]');
            const companyNameInput = registerForm.querySelector('[name="fields[userCompanyName]"]');
            const jobTitleInput = registerForm.querySelector('[name="fields[userJobTitle]"]');
            const addressInput = registerForm.querySelector('[name="fields[userAddress]"]');
            const cityInput = registerForm.querySelector('[name="fields[userCity]"]');
            const countryInput = registerForm.querySelector('[name="fields[userCountry]"]');
            const stateInput = registerForm.querySelector('[name="fields[userStateProvince]"]');
            const postalCodeInput = registerForm.querySelector('[name="fields[userPostalCode]"]');
            const preferredLanguageInput = registerForm.querySelector('[name="fields[userSiteLanguage]"]');

            passwordInput.value = el.querySelector('[type="password"]').value;
            firstNameInput.value = data.FirstName;
            lastNameInput.value = data.LastName;
            emailInput.value = data.Email;
            phoneNumberInput.value = data.Phone;
            companyNameInput.value = data.Company;
            jobTitleInput.value = data.kpmg_shdm_st_segmenttype_id;
            addressInput.value = data.Address;
            cityInput.value = data.City;
            countryInput.value = data.kpmg_shdm_st_country_id_lead;
            stateInput.value = data.State;
            postalCodeInput.value = data.PostalCode;
            preferredLanguageInput.value = data.kpmg_shdm_st_language_id;

            registerForm.submit();
        }
        function loadForm() {
            /* eslint-disable no-undef */
            MktoForms2.loadForm(baseUrl, munchkinId, formId, form => {
                form.onSuccess(() => {
                    handleAnalyticsEvent();
                });

                if (registerUser) {
                    // stuff a non-Marketo password field into the form
                    // this needs to happen before the label logic below
                    handlePasswordField(form);

                    // create a Craft user with the submitted data
                    // this will submit a Craft form, so prevent the Marketo
                    // form from redirecting
                    form.onSuccess(values => {
                        handleRegisterUser(values);
                        return false;
                    });
                }

                // override the follow-up URL as configured in Marketo
                if (successUrl !== '') {
                    form.onSuccess(() => {
                        window.location.href = successUrl;
                        return false;
                    });
                }

                handleCommonLabels();
                handleSelectLabels();
                handleCheckboxes();
                handleDateLabels();
                handleRadios();
                handleNotes();
                handleSubmitBtn();
                handleAsterisks();
                translateContent();

                if (Object.entries(postData).length > 0) {
                    handlePrepopulatedInputs(form);
                }

                // post data from the Teaser form to the Join form
                if (proxyFormUrl !== '') {
                    handleProxy(form);
                }
            });
            /* eslint-enable no-undef */

            /* eslint-disable no-undef */
            MktoForms2.whenReady(f => {
                // populate the hidden language field
                if (siteLanguage !== '') {
                    f.setValues({ kpmg_shdm_st_language_id: siteLanguage });
                }
            });
            /* eslint-enable no-undef */
        }

        // Initialize
        const mktoScript = document.createElement('script');
        mktoScript.type = 'text/javascript';
        mktoScript.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
        mktoScript.onload = loadForm;
        el.prepend(mktoScript);
    }
}
